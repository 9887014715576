<template lang="pug">
om-modal(
  :name="modalName"
  :modal-classes="modalClass"
  :scrollable="true"
  @beforeOpen="beforeOpen"
  @opened="open"
  @beforeClose="beforeClose"
)
  template(v-if="currentPage === 1")
    template(slot="modal-header")
      .w-100.text-center.font-weight-bold.font-size-1--5 {{ modalTitle }}
      .brand-modal-action-icon.cursor-pointer(@click="$modal.hide(modalName)")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body")
      template(v-if="isEvents")
        template(v-for="(rule, type, index) in defaultSettings[settingType]")
          .campaign-setting
            event-box(
              :event="{ type }"
              :class="{ 'mb-4': index !== Object.keys(defaultSettings[settingType]).length - 1 }"
            )
              template(slot="action")
                .setting-action-holder(v-if="activeSettings.includes(type)")
                  i.fas.fa-pencil-alt.cursor-pointer(@click="edit(type)")
                .setting-action-holder.text-center(v-else)
                  add-svg.cursor-pointer(:size="32" @click.native="addRule(type)")
      template(v-if="isFrontendRules")
        .active-personalized-rules(v-if="experience && filteredSettingArray.length")
          .brand-rule-box-title.pt-2.pb-5 {{ $t('experiences.frontendRuleModal.activeRulesTitle') }}
          template(v-for="(rule, index) in filteredSettingArray")
            frontend-rule-box.mb-4(
              :frontendRule="{ type: rule.type }"
              :isNew="rule.type === RULE_KLAVIYO_LIST_AND_SEGMENTS"
            )
              template(slot="action")
                om-button.add-experience-rule(
                  secondary
                  small
                  icon="sign-alt"
                  @click.native="edit(rule.type)"
                ) {{ $t('experiences.frontendRuleModal.personalize') }}
        .personalized-rules-subtitle.w-100.text-center.font-weight-bold.font-size-1--5(
          v-if="experience && canAddRuleInExperienceMode && filteredSettingArray.length"
        ) {{ $t('experiences.frontendRuleModal.subtitle') }}
        template(
          v-for="(rules, category, categoryIndex) in frontendRuleCategories"
          v-if="rules.length > 0"
        )
          .campaign-setting
            .campaign-setting-content
              .d-flex.align-items-center(:class="categoryIndex === 0 ? 'mb-5' : 'my-5'")
                .mr-3.brand-modal-category-name {{ $t(`frontendRuleGroups.${category}`) }}
                .brand-separator
              template(v-for="(type, ruleIndex) in rules")
                frontend-rule-box(
                  :frontendRule="getRuleType(type)"
                  :class="{ 'mb-4': !(category === 'pro' && ruleIndex === rules.length - 1) }"
                  :isNew="type === RULE_KLAVIYO_LIST_AND_SEGMENTS"
                )
                  template(slot="action")
                    template(v-if="!isUsingExperiences")
                      .setting-action-holder(v-if="activeSettings.includes(type)")
                        i.fas.fa-pencil-alt.cursor-pointer(@click="edit(type)")
                      .setting-action-holder(v-else)
                        add-svg.cursor-pointer(:size="32" @click.native="addRule(type)")
                    template(v-else)
                      om-button.add-experience-rule(secondary small @click="modifyRule(type)") {{ $t(wantToEditRule(type) ? 'edit' : 'add') }}
    template(slot="modal-footer")
      om-button.mx-auto.brand-btn-shadow(secondary @click="$modal.hide(modalName)") {{ $t('cancel') }}
  template(v-if="currentPage === 2")
    template(slot="modal-header")
      .text-center
        .font-weight-bold.font-size-1--5.d-inline-block {{ modalFeatureTitle }}
        .small {{ modalFeatureLead }}
      .brand-separator.mt-3
      .brand-modal-action-icon.cursor-pointer(@click="$modal.hide(modalName)")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body")
      .container
        template(v-if="isEvents")
          component(
            :is="camelCaseToDash(ruleType)"
            :currentRule.sync="currentRule"
            :saveBtnClicked="saveBtnClicked"
            @canBeSaved="save"
          )
        template(v-if="isFrontendRules")
          template(v-if="ruleType === RULE_SOURCE")
            component(
              :is="camelCaseToDash(ruleType + 'Condition')"
              :currentRule.sync="currentRule"
              :saveBtnClicked="saveBtnClicked"
              @canBeSaved="save"
            )
          template(v-else)
            component(
              :is="camelCaseToDash(getFlaggedRuleType(ruleType))"
              :currentRule.sync="currentRule"
              :saveBtnClicked="saveBtnClicked"
              :domain="domain"
              :isShopify="isActiveShopifyDomain(domain)"
              :isShoprenter="isActiveShoprenterDomain(domain)"
              @canBeSaved="save"
              :ruleset="ruleset"
              :isEditMode="isEditMode"
              :locale="getLocale"
              :campaign="campaign"
              @changeSavingAccess="onChangeSavingAccess"
              :editMode="editMode"
            )
    template(slot="modal-footer")
      .d-flex.justify-content-center
        om-button.mr-3(ghost @click="cancel()") {{ $t('cancel') }}
        om-button(primary @click="saveBtnClicked++" :disabled="!isSavingEnabled") {{ $t('save') }}

      .end-section.mt-4
        om-toast.mb-4(v-if="showWarningText" :closable="false" type="icon" color="warning")
          div {{ modalFeatureWarning }}
        learn-more-section(
          :settingType="settingType"
          :ruleType="ruleType"
          v-if="!learnMoreSectionV2Rules.includes(ruleType)"
        )
        learn-more-section-v2(v-else :settingType="settingType" :ruleType="ruleType")
</template>

<script>
  import { cloneDeep, get as _get } from 'lodash-es';
  import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';

  import camelCaseToDash from '@/mixins/camelCaseToDash';

  import { getDefaultFrontendRules, getShopifyFrontendRules } from '@/config/frontendRules';
  import defaultCampaignSettings from '@/config/defaultCampaignSettings';
  import en from '@/translations/en.json';
  import { isRecommendedEvent, isRecommendedRule } from '@/config/recommendedSettings';
  import {
    JF_EPV_SUPERADMIN,
    JF_EPV,
    isFeatureEnabled,
    JETFABRIC_EMBEDDED_AND_DC,
  } from '@/utils/features';

  import EventBox from '@/components/CampaignSettings/EventBox';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';
  import * as Triggers from '@/components/CampaignSettings/Trigger/';
  import * as Conditions from '@/components/CampaignSettings/Condition/';
  import TriggerSvg from '@/components/Svg/Trigger/TriggerSvg.vue';
  import FrontendRulesColorBox from '@/components/FrontendRulesColorBox.vue';
  import LearnMoreSection from '@/components/LearnMoreSection.vue';
  import LearnMoreSectionV2 from '@/components/LearnMoreSectionV2.vue';
  import AddSvg from '@/components/Svg/Icons/Add';
  import embeddedV3Mixin from '@/mixins/embeddedV3';
  import dynamicContentMixin from '@/mixins/dynamicContent';
  import isUsingExperiences from '@/mixins/isUsingExperiences';
  import cookie from '@/mixins/cookie';
  import {
    RULE_CUSTOM_VARIABLES,
    RULE_ENGAGED_WITH_CAMPAIGNS,
    RULE_COUNTRY,
    RULE_KLAVIYO_LIST_AND_SEGMENTS,
    RULES_CATEGORIES,
    RULES_WIDE_MODAL,
    RULE_SOURCE,
  } from '@/config/frontendRules/rules';
  import {
    EXCLUDES_EXPERIENCES,
    FEATURE_EXPERIENCES,
    REMOVE_JETFABRIC_EMBEDDED,
  } from '@/config/frontendRules/filters';
  import { track } from '@/services/xray';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      TriggerSvg,
      FrontendRulesColorBox,
      LearnMoreSection,
      LearnMoreSectionV2,
      ...Triggers,
      ...Conditions,
      EventBox,
      FrontendRuleBox,
      AddSvg,
    },

    mixins: [camelCaseToDash, embeddedV3Mixin, dynamicContentMixin, cookie, isUsingExperiences],

    props: {
      settingType: {
        type: String,
        required: true,
        validator: (value) => {
          return value === 'events' || value === 'frontendRules';
        },
      },
      settingArray: {
        type: Array,
        required: true,
      },
      domain: {
        type: String,
      },
      campaign: {
        type: Object,
      },
      editMode: {
        default: 'campaignSettings',
        type: String,
      },
    },

    data() {
      return {
        isEditMode: false,
        needRuleExtractionFromExperienceModal: true,
        currentPage: null,
        ruleType: '',
        experience: null,
        currentRule: {},
        lastScrollPosition: 0,
        modalElement: null,
        activeSvgAnimation: '',
        prevPage: null,
        saveBtnClicked: 0,
        eventTriggerPro: ['javascriptEvent'],
        editedCartRuleGroup: null,
        editedCartRuleExpression: null,
        selectedCartProducts: [],
        RULE_KLAVIYO_LIST_AND_SEGMENTS,
        RULE_SOURCE,
        learnMoreSectionV2Rules: ['campaignProgressState', 'viewedPageV2', 'visitorCartV3'],
        isSavingEnabled: true,
        isDeletionEnabled: false,
      };
    },
    computed: {
      ...mapState(['account']),
      ...mapGetters([
        'brandName',
        'isShopifyDomain',
        'isActiveShoprenterDomain',
        'isActiveShopifyDomain',
        'isKlaviyoDomain',
        'hasAccountFeature',
        'hasSubscriberTargetingFeature',
        'getLocale',
      ]),
      ...paymentGetters(['isPageViewBasedPackage']),
      combinedFrontendRules() {
        const experienceFrontendRules = this.experience?.frontendRules || [];
        return [...this.settingArray, ...experienceFrontendRules];
      },
      combinedRuleTypes() {
        return new Set(this.combinedFrontendRules.map(({ type }) => type));
      },
      frontendRulesInCampaignExperiences() {
        const ruleTypes = this.campaign?.experiences
          ?.flatMap?.(({ frontendRules = [] }) => frontendRules)
          ?.map?.(({ type }) => type);

        return new Set(ruleTypes);
      },
      isEvents() {
        return this.settingType === 'events';
      },
      isFrontendRules() {
        return this.settingType === 'frontendRules';
      },
      modalName() {
        return this.isEvents ? 'new-events-modal' : 'new-frontendrules-modal';
      },
      modalClass() {
        if (this.currentPage === 1 || !RULES_WIDE_MODAL.includes(this.ruleType)) {
          return 'brand-modal-size-normal';
        }
        return 'brand-modal-size-wide';
      },
      modalTitle() {
        if (!this.experience?.name) {
          return this.isEvents ? this.$t('whenToShow') : this.$t('whoToShow');
        }
        return this.$t('experiences.frontendRuleModal.title', {
          experienceName: this.experience.name,
        });
      },
      activeSettings() {
        return this.settingArray.map((s) => s.type);
      },
      activeExperienceSettings() {
        return this.experience?.frontendRules?.map?.((s) => s.type) || [];
      },
      frontendRuleCategories() {
        return !this.experience
          ? this.allFrontendRuleCategories
          : this.frontendRuleCategoriesInExperienceMode;
      },
      isSuperAdminLogin() {
        return _get(this.account, 'isSuperAdmin');
      },
      isSuperadmin() {
        const isJFEPVSuperadmin = isFeatureEnabled(this.features, JF_EPV_SUPERADMIN);
        return isJFEPVSuperadmin && this.isSuperAdminLogin;
      },
      hasJFNewScriptFlag() {
        return this.hasAccountFeature(JETFABRIC_EMBEDDED_AND_DC);
      },
      rulesFeatureSet() {
        const features = [];
        if (this.experience) features.push(FEATURE_EXPERIENCES);
        if (this.hasJFNewScriptFlag === false && (this.isEmbeddedV3 || this.isDynamicContent)) {
          features.push(REMOVE_JETFABRIC_EMBEDDED);
        }

        return features;
      },
      filteredSettingArray() {
        return this.settingArray.filter(
          ({ type }) => !EXCLUDES_EXPERIENCES.includes(type) && !this.isHiddenRuleType(type),
        );
      },
      allFrontendRuleCategories() {
        const rules = this.isShopifyDomain(this.domain)
          ? getShopifyFrontendRules(this.ruleset, this.rulesFeatureSet)
          : getDefaultFrontendRules(this.ruleset, this.rulesFeatureSet);

        if (!this.isKlaviyoDomain(this.domain)) {
          rules[RULES_CATEGORIES.VISITORS] = rules.visitors.filter(
            (ruleType) => ruleType !== RULE_KLAVIYO_LIST_AND_SEGMENTS,
          );
        }

        if (this.isSuperadmin || isFeatureEnabled(this.features, JF_EPV)) {
          rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].push('enhancedPageViews');
        }

        return rules;
      },
      features() {
        return _get(this, 'account.features', []);
      },
      defaultSettings() {
        return defaultCampaignSettings(this.features);
      },
      isCampaignProgressStateTurbo() {
        return this.ruleType === RULE_ENGAGED_WITH_CAMPAIGNS;
      },
      realTranslationKey() {
        let transKey = `${this.settingType}.${this.ruleType}`;
        if (this.isCampaignProgressStateTurbo) {
          transKey += '.turbo';
        }

        return transKey;
      },
      modalFeatureTitle() {
        const ruleName = this.$t(`${this.realTranslationKey}.title`, {
          brand: this.brandName,
        });

        const isCampaignRule = this.activeSettings.includes(this.ruleType);
        if (!this.experience || !isCampaignRule) return ruleName;

        return this.$t('experiences.frontendRuleModal.personalizedRuleTitle', {
          experienceName: this.experience.name,
          ruleName,
        });
      },
      modalFeatureLead() {
        return this.$t(`${this.realTranslationKey}.lead`, {
          brand: this.brandName,
        });
      },
      modalFeatureWarning() {
        return this.$t(`${this.realTranslationKey}.warning`, {
          brand: this.brandName,
        });
      },
      showWarningText() {
        return this.isEmbeddedV3 && this.ruleType === RULE_CUSTOM_VARIABLES;
      },
      frontendRuleCategoriesInExperienceMode() {
        const categories = {};

        Object.entries(this.allFrontendRuleCategories).forEach(([key, rules]) => {
          const notActiveSettings = rules.filter(
            (rule) => !this.activeSettings.includes(rule) && !EXCLUDES_EXPERIENCES.includes(rule),
          );

          if (notActiveSettings.length) {
            categories[key] = notActiveSettings;
          }
        });

        return categories;
      },
      canAddRuleInExperienceMode() {
        return Object.values(this.frontendRuleCategories).some((rules) => !!rules.length);
      },
    },
    mounted() {
      this.setNeedRuleExtractionFromExperienceModal();
    },
    methods: {
      isRecommendedEvent,
      isRecommendedRule,
      setNeedRuleExtractionFromExperienceModal() {
        const showModal = this.getCookie('x-om-show-rule-is-part-of-experience-modal');
        this.needRuleExtractionFromExperienceModal = showModal !== '0';
      },
      beforeOpen(event) {
        this.lastScrollPosition = 0;
        this.prevPage = null;
        this.saveBtnClicked = 0;
        this.isSavingEnabled = true;
        this.ruleset = _get(event, 'params.ruleset', 'default');
        this.experience = _get(event, 'params.experience');

        if (event.params && event.params.type) {
          this.edit(event.params.type, event.params.currentCartRules, false);
        } else if (event.params && event.params.ruleType) {
          this.needRuleExtractionFromExperienceModal =
            event.params?.needRuleExtractionFromExperienceModal ?? true;
          this.addRule(event.params.ruleType, event.params.currentCartRules);
        } else {
          this.isEditMode = false;
          if (event.params && event.params.currentCartRules) {
            this.currentPage = event.params.currentPage;
            this.currentRule = event.params.currentCartRules;
            this.ruleType = event.params.ruleType;
          } else {
            this.currentPage = 1;
          }
        }
      },

      getRuleType(type) {
        return { type };
      },

      edit(type, currentCartRules, fromList = true) {
        this.setNeedRuleExtractionFromExperienceModal();
        this.isEditMode = true;
        this.track('open', type);
        // if we edit from the modal, we set the prev page as well
        if (fromList) {
          this.goToPage(2);
        } else {
          this.currentPage = 2;
        }
        this.ruleType = type;
        if (currentCartRules) {
          this.currentRule = currentCartRules;
        } else {
          this.resetCurrentRule();
        }
      },

      open() {
        this.modalElement = document.querySelector('.v--modal-overlay.scrollable');
        this.modalElement.addEventListener('scroll', this.saveScrollPosition);
      },

      saveScrollPosition() {
        if (this.modalElement.scrollTop > 0 && this.currentPage === 1) {
          this.lastScrollPosition = this.modalElement.scrollTop;
        }
      },

      beforeClose() {
        this.modalElement.removeEventListener('scroll', this.saveScrollPosition);
        this.isEditMode = false;
        this.experience = null;
      },
      addRule(ruleType, currentCartRules) {
        if (
          !this.experience &&
          this.frontendRulesInCampaignExperiences.has(ruleType) &&
          this.needRuleExtractionFromExperienceModal
        ) {
          this.$emit('ruleIsPartOfExperienceWarning', { ruleType });
          return;
        }
        this.addRuleByRuleType(ruleType, currentCartRules);
      },
      addRuleByRuleType(ruleType, currentCartRules) {
        this.track('open', ruleType);
        this.ruleType = ruleType;
        if (currentCartRules) {
          this.currentRule = currentCartRules;
        } else {
          this.resetCurrentRule();
        }
        const nextPage = this.currentPage < 2 ? this.currentPage + 1 : this.currentPage;
        this.goToPage(nextPage);
      },

      cancel() {
        this.resetCurrentRule();
        if (this.prevPage === null) {
          this.$modal.hide(this.modalName);
        } else {
          this.goToPage(this.currentPage - 1);
          this.$nextTick(() => {
            this.modalElement.scrollTop = this.lastScrollPosition;
          });
          this.isEditMode = false;
        }
      },

      resetCurrentRule() {
        const defaultSettings = this.defaultSettings[this.settingType][this.ruleType];

        if (this.isEditMode) {
          const currentRule = this.combinedFrontendRules.find(
            (rule) => rule.type === this.ruleType,
          );
          this.currentRule = cloneDeep(currentRule);
        } else if (typeof defaultSettings === 'function') {
          this.currentRule = defaultSettings({
            store: this.$store,
            domain: this.domain,
            isEmbeddedV3: this.isEmbeddedV3,
            isDynamicContent: this.isDynamicContent,
            JFFlag: this.hasJFNewScriptFlag,
          });
        } else {
          this.currentRule = cloneDeep(defaultSettings);
        }
      },

      save() {
        if (this.currentRule.type === RULE_COUNTRY) {
          this.currentRule.options.countries.sort((a, b) => {
            const ca = a.label.toLowerCase();
            const cb = b.label.toLowerCase();
            if (ca < cb) {
              return -1;
            }
            if (ca > cb) {
              return 1;
            }
            return 0;
          });
        }

        const currentRuleWithType = { type: this.ruleType, ...this.currentRule };
        this.track('add', this.ruleType);
        if (!this.experience) {
          if (this.isEditMode) {
            this.$emit('editRule', currentRuleWithType);
          } else {
            // eslint-disable-next-line
            this.settingArray.push(currentRuleWithType);
            this.$emit('update:settingArray', this.settingArray);
          }
        } else {
          this.$emit('editRule', {
            frontendRule: currentRuleWithType,
            experienceId: this.experience._id,
          });
        }

        this.$modal.hide(this.modalName);
      },
      track(configType, configTitle) {
        const eventName = `${configType}${this.isEvents ? 'Trigger' : 'Rule'}`;
        const translationKey = this.isEvents ? 'events' : 'frontendRules';
        const type = _get(en, `${translationKey}.${configTitle}.title`) || configTitle;
        track(eventName, { type });
      },
      goToPage(num) {
        this.prevPage = this.currentPage;
        this.currentPage = num;
      },
      getFlaggedRuleType(raw) {
        if (this.isCampaignProgressStateTurbo) {
          return `${raw}Turbo`;
        }

        return raw;
      },
      wantToEditRule(rule) {
        return this.combinedRuleTypes.has(rule);
      },
      modifyRule(rule) {
        if (this.wantToEditRule(rule)) {
          return this.edit(rule);
        }
        this.addRule(rule);
      },
      onChangeSavingAccess(value) {
        this.isSavingEnabled = value;
      },
      isHiddenRuleType() {
        return false;
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  [data-modal="new-frontendrules-modal"]
    .brand-modal .brand-modal-body
      padding: .5rem 2rem

  .experiences
    .campaign-setting
      padding: 0 .85rem

  .brand-rule-box .add-experience-rule
    flex: 0 0 150px
  .active-personalized-rules
    background: $om-gray-200
    border-radius: 8px
    padding: 0.75rem
    .brand-rule-box-lead
      font-size: .75rem
    .frontend-rules-color-box
      width: 40px
      height: 40px
      img
        width: 20px
        height: auto
    .brand-rule-box
      .brand-rule-box-title
        font-size: .85rem

    .brand-rule-box-title
      text-align: center
  .personalized-rules-subtitle
    margin: 2rem 0
</style>
